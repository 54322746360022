var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lg:w-9/12 mx-auto" },
    [
      _c(
        "h5",
        {
          staticClass:
            "md:text-2xl text-xl text-center font-bold primary-text mt-4",
        },
        [_vm._v(" Employment details ")]
      ),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "flex items-center" }, [
          _c("div", { staticClass: "radio" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.employment,
                  expression: "employment",
                },
              ],
              attrs: { id: "employed", name: "radio", type: "radio" },
              domProps: {
                value: _vm.employmentStatus.employed,
                checked: _vm._q(_vm.employment, _vm.employmentStatus.employed),
              },
              on: {
                change: function ($event) {
                  _vm.employment = _vm.employmentStatus.employed
                },
              },
            }),
            _c("label", {
              staticClass: "radio-label",
              attrs: { for: "employed" },
            }),
          ]),
          _vm._m(0),
        ]),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "radio" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.employment,
                  expression: "employment",
                },
              ],
              attrs: { id: "self-employed", name: "radio", type: "radio" },
              domProps: {
                value: _vm.employmentStatus.seflEmployed,
                checked: _vm._q(
                  _vm.employment,
                  _vm.employmentStatus.seflEmployed
                ),
              },
              on: {
                change: function ($event) {
                  _vm.employment = _vm.employmentStatus.seflEmployed
                },
              },
            }),
            _c("label", {
              staticClass: "radio-label",
              attrs: { for: "self-employed" },
            }),
          ]),
          _vm._m(1),
        ]),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "radio" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.employment,
                  expression: "employment",
                },
              ],
              attrs: { id: "unemployed", name: "radio", type: "radio" },
              domProps: {
                value: _vm.employmentStatus.unEmployed,
                checked: _vm._q(
                  _vm.employment,
                  _vm.employmentStatus.unEmployed
                ),
              },
              on: {
                change: function ($event) {
                  _vm.employment = _vm.employmentStatus.unEmployed
                },
              },
            }),
            _c("label", {
              staticClass: "radio-label",
              attrs: { for: "unemployed" },
            }),
          ]),
          _vm._m(2),
        ]),
      ]),
      _c("Button", {
        staticClass: "mt-12",
        attrs: { width: "w-full", text: "Continue", loading: _vm.loading },
        on: { click: _vm.handleEmploymentType },
      }),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showExplainer },
          on: { close: _vm.handleExplainerClose },
        },
        [
          _c("h1", { staticClass: "text-brandPurple text-xl font-bold" }, [
            _vm._v("We are sorry!"),
          ]),
          _c("p", { staticClass: "text-grey mt-2" }, [
            _vm._v(
              " We do not currently offer credit to self-employed/unemployed persons. "
            ),
          ]),
          _c("p", { staticClass: "font-semibold text-grey mt-3" }, [
            _vm._v(
              " However we will let you know when this offer is available "
            ),
          ]),
          _c("h3", { staticClass: "text-dark text-lg font-semibold mt-3" }, [
            _vm._v(" What can you do with a Zilla account? "),
          ]),
          _c("p", { staticClass: "text-grey" }, [
            _vm._v(
              " For now, you can use Zilla to make payments in full at your favourite stores and enjoy 2% instant cashback. "
            ),
          ]),
          _c("Button", {
            staticClass: "mt-4",
            attrs: { text: "close", width: "w-full" },
            on: { click: _vm.handleExplainerClose },
          }),
          _c(
            "p",
            {
              staticClass:
                "text-center font-semibold my-4 text-brandPurple cursor-pointer",
              on: { click: _vm.handleAbort },
            },
            [_vm._v(" Go to dashboard ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("p", { staticClass: "font-semibold text-dark" }, [_vm._v("Employed")]),
      _c("p", { staticClass: "md:text-sm text-xs text-grey" }, [
        _vm._v(
          " You work for an organisation or own a registered/verifiable business/company "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("p", { staticClass: "font-semibold text-dark" }, [
        _vm._v("Self employed"),
      ]),
      _c("p", { staticClass: "md:text-sm text-xs text-grey" }, [
        _vm._v(
          " You work for yourself as a freelancer or you own a small/unregistered business. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("p", { staticClass: "font-semibold text-dark" }, [
        _vm._v("Not employed"),
      ]),
      _c("p", { staticClass: "md:text-sm text-xs text-grey" }, [
        _vm._v(" You are not currently in an active employment. "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }