<template>
  <div class="lg:w-9/12 mx-auto">
    <!-- <div class="inline-flex items-center mb-5 text-lightGrey-2 cursor-pointer" @click.stop="$emit('back')">
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                <path
                    d="M7.08004 0.993291C6.75337 0.666624 6.22671 0.666624 5.90004 0.993291L0.360039 6.53329C0.100039 6.79329 0.100039 7.21329 0.360039 7.47329L5.90004 13.0133C6.22671 13.34 6.75337 13.34 7.08004 13.0133C7.40671 12.6866 7.40671 12.16 7.08004 11.8333L2.25337 6.99996L7.0867 2.16662C7.4067 1.84662 7.40671 1.31329 7.08004 0.993291Z"
                    fill="#6B6873" />
            </svg>
            <p>
                <span class="ml-3 text-sm">Back</span>
            </p>
        </div> -->
    <h5 class="md:text-2xl text-xl text-center font-bold primary-text mt-4">
      Employment details
    </h5>
    <div class="mt-4">
      <div class="flex items-center">
        <div class="radio">
          <input
            id="employed"
            name="radio"
            type="radio"
            v-model="employment"
            :value="employmentStatus.employed"
          />
          <label for="employed" class="radio-label"></label>
        </div>
        <div class="ml-2">
          <p class="font-semibold text-dark">Employed</p>
          <p class="md:text-sm text-xs text-grey">
            You work for an organisation or own a registered/verifiable
            business/company
          </p>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="flex">
        <div class="radio">
          <input
            id="self-employed"
            name="radio"
            type="radio"
            v-model="employment"
            :value="employmentStatus.seflEmployed"
          />
          <label for="self-employed" class="radio-label"></label>
        </div>
        <div class="ml-2">
          <p class="font-semibold text-dark">Self employed</p>
          <p class="md:text-sm text-xs text-grey">
            You work for yourself as a freelancer or you own a
            small/unregistered business.
          </p>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="flex">
        <div class="radio">
          <input
            id="unemployed"
            name="radio"
            type="radio"
            v-model="employment"
            :value="employmentStatus.unEmployed"
          />
          <label for="unemployed" class="radio-label"></label>
        </div>
        <div class="ml-2">
          <p class="font-semibold text-dark">Not employed</p>
          <p class="md:text-sm text-xs text-grey">
            You are not currently in an active employment.
          </p>
        </div>
      </div>
    </div>
    <Button
      width="w-full"
      class="mt-12"
      text="Continue"
      :loading="loading"
      @click="handleEmploymentType"
    />

    <Modal :display="showExplainer" @close="handleExplainerClose">
      <h1 class="text-brandPurple text-xl font-bold">We are sorry!</h1>
      <p class="text-grey mt-2">
        We do not currently offer credit to self-employed/unemployed persons.
      </p>
      <p class="font-semibold text-grey mt-3">
        However we will let you know when this offer is available
      </p>
      <h3 class="text-dark text-lg font-semibold mt-3">
        What can you do with a Zilla account?
      </h3>
      <p class="text-grey">
        For now, you can use Zilla to make payments in full at your favourite
        stores and enjoy 2% instant cashback.
      </p>
      <Button
        text="close"
        width="w-full"
        class="mt-4"
        @click="handleExplainerClose"
      />
      <p
        class="text-center font-semibold my-4 text-brandPurple cursor-pointer"
        @click="handleAbort"
      >
        Go to dashboard
      </p>
    </Modal>
  </div>
</template>
<script>
  import { Button } from "@/UI/Button";
  import { Modal } from "@/UI/Modals";
  import { CUSTOMER_EMPLOYMENT_TYPE } from "@/utils/api/userInfo";
  export default {
    components: {
      Button,
      Modal,
    },
    data: () => ({
      employment: "EMPLOYED",
      loading: false,
      employmentStatus: {
        employed: "EMPLOYED",
        seflEmployed: "SELF_EMPLOYED",
        unEmployed: "UNEMPLOYED",
      },
      showExplainer: false,
    }),
    methods: {
      handleEmploymentType() {
        this.loading = true;
        CUSTOMER_EMPLOYMENT_TYPE(this.employment)
          .then((res) => {
            if (this.employment !== this.employmentStatus.employed) {
              this.showExplainer = true;
            } else {
              this.$emit("next");
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      handleExplainerClose() {
        this.showExplainer = false;
      },
      handleAbort() {
        this.handleExplainerClose();
        this.$router.push("/");
      },
    },
  };
</script>
<style scoped>
  .radio {
    margin: 0.5rem;
  }

  .radio input[type="radio"] {
    position: absolute;
    opacity: 0;
  }

  .radio input[type="radio"] + .radio-label:before {
    content: "";
    background: white;
    border-radius: 100%;
    border: 2px solid #d5ccff;
    display: inline-block;
    width: 1.125rem;
    height: 1.125rem;
    position: relative;
    top: -0.2em;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
  }

  .radio input[type="radio"]:checked + .radio-label:before {
    background-color: #6930c3;
    box-shadow: inset 0 0 0 4px #f4f4f4;
  }

  .radio input[type="radio"]:focus + .radio-label:before {
    outline: none;
    border-color: #d5ccff;
  }

  .radio input[type="radio"]:disabled + .radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
  }

  .radio input[type="radio"] + .radio-label:empty:before {
    margin-right: 0;
  }
</style>
